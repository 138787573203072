// $(document).ready(function(){
// 	new WOW().init();
// });

function doajax(c) {
	var t, a = $(c).serialize();
	var o = "php/contactform.php";
	// if ("#form-contacts" == c)
	// 	var o = "php/contactform.php";
	// else if ("#form-callback" == c)
	// 	o = "php/callbackform.php";
	return $.ajax({
		type: "POST",
		url: o,
		data: a,
		success: function (msg) {
			msg =="success" ? (t = "<div class='result_success'>Спасибо за заявку! Я свяжусь с Вами в ближайшее время.</div>",
				$(c)[0].reset()) : t = "<div class='result_error'>"+ msg +"</div>", $(c + " .form-result").empty(), $(c + " .form-result").append(t)
		},
		beforeSend: function () {
			$(c).find(":input").attr("disabled", !0),
			$(c + " .ajax-loader").css("display", "block")
		},
		complete: function () {
			$(c).find(":input").attr("disabled", !1),
			"#form-callback" == c && $(".form-callback__inner").hide(),
			$(c + " .ajax-loader").css("display", "none"),
			$(c + " .form-result").css("display", "block");
			if ("#form-contacts" == c){
				grecaptcha.reset()
			}
		}
	}), !1
}

$(document).ready(function () {
	$(".nav-link, a.btn").on("click", function (a) {
		a.preventDefault();
		var c = $(this).attr("href"),
			t = $(c).offset().top;
		$("body,html").animate({
			scrollTop: t
		}, 1e3)
	})
}),

// $(document).ready(function() {
//   $(".navbar-collapse").collapse("hide")
// }),

$('.menu-list').on('click', function () {
		$('.menu-button').toggleClass("menu-button_to-open"),
		$('.menu-button').toggleClass("menu-button_to-close"),
		$('.menu-button').hasClass("menu-button_to-close") ? $(".menu-wrapper").addClass("menu_visible") : $(".menu-wrapper").removeClass("menu_visible")
});


$("#form-callback").submit(function (a) {
	a.preventDefault(), !1 === $("#form-callback")[0].checkValidity() ? (a.stopPropagation(), $("#form-callback").addClass("was-validated")) : (doajax("#form-callback"), $("#form-callback").removeClass("was-validated"))
})

$("#form-contacts").submit(function (a) {
	a.preventDefault(), !1 === $("#form-contacts")[0].checkValidity() ? (a.stopPropagation(), $("#form-contacts").addClass("was-validated")) : (doajax("#form-contacts"), $("#form-contacts").removeClass("was-validated"))
})

$(".contacts__close-btn").on("click", function (a) {
	$("#contacts__form-result p").remove(),
	$("#contacts__form-result").css("display", "none"),
	$("#form-contacts").find(":input").attr("disabled", !1)
})

$(document).ready(function () {
	$(".menu-button").click(function () {
		$(this).toggleClass("menu-button_to-open"),
		$(this).toggleClass("menu-button_to-close"),
		$(this).hasClass("menu-button_to-close") ? $(".menu-wrapper").addClass("menu_visible") : $(".menu-wrapper").removeClass("menu_visible")
	})
})

$(document).ready(function () {
	$(".btn-callback").click(function (a) {
		a.preventDefault(), $(".form-callback")[0].reset(), $(".form-callback__inner").css("display", "block"), $(".callback-popup__overlay").fadeIn(400, function () {
			$(".callback-popup__content").css("display", "block").animate({
				opacity: 1
			}, 200)
		})
	}), $(".callback-popup__btn-close, .callback-popup__overlay").click(function () {
		$(".form-callback__result").html(""), $(".form-callback__result").css("display", "none"), $("#form-callback").removeClass("was-validated"), $(".callback-popup__content").animate({
			opacity: 0
		}, 200, function () {
			$(this).css("display", "none"), $(".callback-popup__overlay").fadeOut(400)
		})
	})
});
